import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'avatar-group',
  templateUrl: './avatar-group.component.html',
  styleUrls: ['./avatar-group.component.scss'],
})
export class AvatarGroupComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
