import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { StorageService } from 'src/app/services/storage.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  constructor(private navCtrl: NavController, private modalCtrl: ModalController,
    private authService: AuthService, private storageService: StorageService ,
    private toastService: ToastService, private ionLoader: LoaderService) { }

  ngOnInit() {
  }

  postData = {
    email:"",
    password:""
  };  
  // this.storageService.get('user_data').then((val) => {
  //   console.log('Your json is ---:', JSON.parse(val));
  //   if(JSON.parse(val)){
  //     // this.ionLoader.hideLoader();
  //     this.user = JSON.parse(val).id;
  //     this.postData.client = this.user;
  closeModal() {
    this.modalCtrl.dismiss();
  }

  validateInputs() {
    console.log(this.postData);
    let email = this.postData.email.trim();
    let password = this.postData.password.trim();
    return (
      this.postData.email &&
      this.postData.password &&
      email.length > 0 &&
      password.length > 0
    );
  }

  login() {
    if (this.validateInputs()) {
      this.ionLoader.showLoader().then(() => {
        this.authService.login(this.postData).subscribe(
          (res: any) => {
            this.ionLoader.hideLoader();
            if (res.id != null) {
              console.log(res);
                  // Storing the User data.
                this.storageService
                .store('user_data', res)
                .then( res => {
                  this.navCtrl.navigateRoot('/tabs/home');
                  this.closeModal();
                });
             
            } else {
              this.toastService.presentToast("Login Error");
            }
          },
          (error: any) => {
            this.ionLoader.hideLoader();
            this.toastService.presentToast('Login Error ...');
          }
        );
      })
    
    } else {
      this.toastService.presentToast(
        "Enter required data"
      );
    }

    // 
  }

}
