import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'post-comment-item',
  templateUrl: './post-comment-item.component.html',
  styleUrls: ['./post-comment-item.component.scss'],
})
export class PostCommentItemComponent implements OnInit {
  @Input() isReply: boolean = false;

  constructor() { }

  ngOnInit() {}

}
