import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ThemeService } from './services/theme.service';
import { Plugins } from '@capacitor/core';
// import "capacitor-hms-push-kit";
const { PushKit } = Plugins;
const { TextDetectionMlKit } = Plugins;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private themeService: ThemeService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      PushKit.getToken().then(res=>{
        console.log("iT",res.token);//Log the received token
      }).catch(err=>{
        console.error("iT",err);//Log the err in case the token couldn't be received 
      });
    });
    
    this.themeService.restore();
  }
}
