import { Component, OnInit, Input } from '@angular/core';

type TNotificationType = 'PROMOTION' | 'JOB' | 'NEWS';

@Component({
  selector: 'notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent implements OnInit {
  @Input() type: TNotificationType = 'PROMOTION';
  @Input() usersScore
  constructor() { }

  ngOnInit() {}

}
