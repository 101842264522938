import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from './http.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpService: HttpService,
    private storageService: StorageService,
    private router: Router
  ) {}

  getUserData() {
    this.storageService.get('user_data').then(res => {
      console.log(res);
     // this.userData$.next(res);   
    });
  }

  login(postData: any): Observable<any> {
    return this.httpService.post('users/login', postData);
  }

  loginGoogle(postData: any): Observable<any> {
    return this.httpService.post('users/login/google', postData);
  }

  join(postData: any): Observable<any> {
    return this.httpService.post('users', postData);
  }

  post(postData: any): Observable<any> {
    return this.httpService.post('post', postData);
  }

  getJobs(): Observable<any> {
    return this.httpService.get2('jobs/ids');
  }

  getJobDetail(postData: any): Observable<any> {
    return this.httpService.get('jobs', postData);
  }
  
  getQuiz(postData: any): Observable<any> {
    return this.httpService.get('quiz', postData);
  }

  getpost(postData: any): Observable<any> {
    return this.httpService.get('post', postData);
  }

  getAllPost(): Observable<any> {
    return this.httpService.get2('post/all');
  }




  logout() {
    this.storageService.clear();
   // this.userData$.next('');
    this.router.navigate(['home/dashboard', {load: 'yes'} ]);
  }
}
