import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  isFocused: boolean = false;
  keyword: string = '';

  @Output() onFocus = new EventEmitter();
  @Output() onBlur = new EventEmitter();

  constructor(private storageService: StorageService) { }
  
  user = {
    image: ''
  }

  ngOnInit() {
    
    this.storageService
          .get('user_data')
          .then(res => {
            // console.log(JSON.parse(res));
            if(JSON.parse(res).image == '0')
              this.user.image = 'assets/img/user.png'
            else this.user.image = JSON.parse(res).image
    })  
         
  }

  onSearchFocus() {
    this.isFocused = true;
    this.onFocus.emit();
  }

  onSearchBlur() {
    this.isFocused = false;
    this.keyword = '';
    this.onBlur.emit();
  }

}
