import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { PostItemComponent } from './post-item/post-item.component';
import { PostReactionsComponent } from './post-reactions/post-reactions.component';
import { PostCommentItemComponent } from './post-comment-item/post-comment-item.component';
import { PostCommentsComponent } from './post-comments/post-comments.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { TopbarComponent } from './topbar/topbar.component';
import { InvitationItemComponent } from './invitation-item/invitation-item.component';
import { NewsletterItemComponent } from './newsletter-item/newsletter-item.component';
import { PageItemComponent } from './page-item/page-item.component';
import { ConnectItemComponent } from './connect-item/connect-item.component';
import { PostVisibilityComponent } from './post-visibility/post-visibility.component';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { JobItemComponent } from './job-item/job-item.component';
import { MessageItemComponent } from './message-item/message-item.component';
import { ChatItemComponent } from './chat-item/chat-item.component';
import { SearchSuggestionsComponent } from './search-suggestions/search-suggestions.component';
import { AvatarGroupComponent } from './avatar-group/avatar-group.component';
import { RandomAvatarComponent } from './random-avatar/random-avatar.component';
import { StoriesBarComponent } from './stories-bar/stories-bar.component';
import { SkeletonPostComponent } from './skeleton-post/skeleton-post.component';
import { PersonItemComponent } from './person-item/person-item.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
  ],
  declarations: [
    PostItemComponent,
    PostReactionsComponent,
    PostCommentsComponent,
    PostCommentItemComponent,
    SideMenuComponent,
    TopbarComponent,
    InvitationItemComponent,
    NewsletterItemComponent,
    PageItemComponent,
    ConnectItemComponent,
    PostVisibilityComponent,
    NotificationItemComponent,
    JobItemComponent,
    MessageItemComponent,
    ChatItemComponent,
    SearchSuggestionsComponent,
    AvatarGroupComponent,
    RandomAvatarComponent,
    StoriesBarComponent,
    SkeletonPostComponent,
    PersonItemComponent,
  ],
  providers: [],
  exports: [
    PostItemComponent,
    PostReactionsComponent,
    PostCommentsComponent,
    PostCommentItemComponent,
    SideMenuComponent,
    TopbarComponent,
    InvitationItemComponent,
    NewsletterItemComponent,
    PageItemComponent,
    ConnectItemComponent,
    PostVisibilityComponent,
    NotificationItemComponent,
    JobItemComponent,
    MessageItemComponent,
    ChatItemComponent,
    SearchSuggestionsComponent,
    AvatarGroupComponent,
    RandomAvatarComponent,
    StoriesBarComponent,
    SkeletonPostComponent,
    PersonItemComponent,
  ]
})

export class SharedModule {}
