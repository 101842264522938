import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'job-item',
  templateUrl: './job-item.component.html',
  styleUrls: ['./job-item.component.scss'],
})
export class JobItemComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
