import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StoryPage } from '../../pages/story/story.page';

@Component({
  selector: 'stories-bar',
  templateUrl: './stories-bar.component.html',
  styleUrls: ['./stories-bar.component.scss'],
})
export class StoriesBarComponent implements OnInit {

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    
  }

  async openStory() {
    const modal = await this.modalCtrl.create({
      component: StoryPage
    });
    return await modal.present();
  }

}
