import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss'],
})
export class MessageItemComponent implements OnInit {
  @Input() data: any;

  constructor() { }

  ngOnInit() {}

  isUnread() {
    return this.data < 3;
  }

}
