import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
// import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard implements CanLoad {
  constructor(public storageService: StorageService, public router: Router) {}
  canLoad(): Promise<boolean> {
    return new Promise(resolve => {
      this.storageService
        .get('user_data')
        .then(res => {
          if (res) {
            this.router.navigate(['/tabs/home']);
            resolve(false);
          } else resolve(true);
        })
        .catch(err => {
          resolve(true);
        });
    });
  }
}
