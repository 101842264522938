import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'search-suggestions',
  templateUrl: './search-suggestions.component.html',
  styleUrls: ['./search-suggestions.component.scss'],
})
export class SearchSuggestionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
