import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  showRecentList: boolean = false;
  showGroupList: boolean = false;
  showHashTagList: boolean = false;
  isDark: boolean;

  
  constructor(private router: Router, private themeService: ThemeService, private storageService: StorageService,
    private authService: AuthService) { 

  }

  user = {
    name: "",
    image: ""
  }

  jobsList = []

  ngOnInit() {
    this.toggle('showRecentList');
    
    this.themeService.getCurrentSetting()
      .then(val => {
        this.isDark = val;
    })
  }

  

  goToProfile() {
    // transform: '/tabs/home' --> 'tabs/home' --> ['tabs', 'home']
    const splittedRoute = this.router.url.slice(1).split('/');

    // Note: this works because we set Profile page at level-3 route in src/app/tabs/tabs.router.module.ts
    if (splittedRoute.length >= 2) {
      this.router.navigate([`${splittedRoute[0]}/${splittedRoute[1]}/profile`]);
    }
  }

  toggle(target: string) {
    this[target] = !this[target];
    this.storageService
      .get('user_data')
      .then(res => {
         const r =  JSON.parse(res)        
        if(r.image == '0')
          this.user.image = 'assets/img/user.png'
        else this.user.image = r.image
        
        this.user.name = r.firstname + ' ' + r.lastname
        
    })

    // this.storageService
    // .get('jobs')
    // .then(res => {
    //    this.jobsList =  JSON.parse(res)  
    //    console.log(this.jobsList);
      
    // })

  }

  toggleDarkMode(isDark: boolean) {
    this.themeService.toggleDarkMode(isDark);
  }

  logOut(){
      this.storageService.removeItem('user_data').then(() => {
        this.router.navigate(['/landing']);
      });
  }

}
