import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { Plugins } from '@capacitor/core';
import { Storage } from '@ionic/storage';

// const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})

export class StorageService {

  constructor(private storage: Storage,     private router: Router) { }

  // Store the value
  async store(storageKey: string, value: any) {
    console.log('storing....')
    // const encryptedValue = btoa(escape(JSON.stringify(value)));
    const encryptedValue = JSON.stringify(value);
    console.log(encryptedValue);
    await this.storage.set(
      storageKey,
      encryptedValue
    );
  
    console.log('storing done !')
  }

  // Get the value
  async get(storageKey: string) {
    return await this.storage.get(storageKey);
  }

  // JSON "get" example
  async getObject() {
    const ret = await this.storage.get('user');
    const user = JSON.parse(ret.value);
  }

  async setItem() {
    await this.storage.set(
      'name',
      'Max'
    );
  }

  async removeItem(key) {
    await this.storage.remove(
      key
    );
    this.router.navigate(['']);
  }

  async keys() {
    const keys = await this.storage.keys();
    console.log('Got keys: ', keys);
  }

  async clear() {
    await this.storage.clear();
  }
}
