import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { NewPostPage } from './new-post.page';
import { PostVisibilityComponent } from '../../components/post-visibility/post-visibility.component';

const routes: Routes = [
  {
    path: '',
    component: NewPostPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
  ],
  declarations: [NewPostPage],
  entryComponents: [
    PostVisibilityComponent,
  ]
})
export class NewPostPageModule {}
