import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  post(serviceName: string, data: any) {
    const headers = new HttpHeaders();
    const options = { headers: headers, withCredintials: false };
    const url = environment.apiUrl + serviceName;
    
    return this.http.post(url, JSON.stringify(data), options);
  }

  // post2(serviceName: string, data: any) {
  //   const headers = new HttpHeaders();
  //   const options = { headers: headers, withCredintials: false };
  //   const url = environment.siteUrl + serviceName;
    
  //   return this.http.post(url, JSON.stringify(data), options);
  // }

  get(serviceName: string, data: any) {
   
    return this.http.get(environment.apiUrl+serviceName+"/"+data, { "responseType": "text" });
    //return this.http.post(url, JSON.stringify(data), options);
  }

  get2(serviceName: string) {
   
    return this.http.get(environment.apiUrl+serviceName, { "responseType": "text" });
    //return this.http.post(url, JSON.stringify(data), options);
  }
}
