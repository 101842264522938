import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { PostVisibilityComponent } from '../../components/post-visibility/post-visibility.component';

@Component({
  selector: 'app-new-post',
  templateUrl: './new-post.page.html',
  styleUrls: ['./new-post.page.scss'],
})
export class NewPostPage implements OnInit {

  constructor(public modalCtrl: ModalController, private authService: AuthService, private storageService: StorageService) { }

  ngOnInit() {
    this.storageService
      .get('user_data')
      .then(res => {
         const r =  JSON.parse(res)        
        if(r.image == '0')
          this.user.image = 'assets/img/user.png'
        else this.user.image = r.image
        
        this.user.name = r.firstname + ' ' + r.lastname
        this.user.id = r.id
        
    })
  }

  user = {
    id: "",
    name: "",
    image: ""
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async openOptions() {
    const modal = await this.modalCtrl.create({
      component: PostVisibilityComponent,
      cssClass: 'modal-custom-half',
    });
    return await modal.present();
  }

}
