import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'newsletter-item',
  templateUrl: './newsletter-item.component.html',
  styleUrls: ['./newsletter-item.component.scss'],
})
export class NewsletterItemComponent implements OnInit {
  @Input() news = "" 
  constructor() { }

  ngOnInit() {}

}
