/**
  Random Avatar for Ionic 5
  v1.1.0 (23/11/2020)
  Hieu Pham (https://www.takethatdesign.com)
*/

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'random-avatar',
  templateUrl: './random-avatar.component.html',
  styleUrls: ['./random-avatar.component.scss'],
})
export class RandomAvatarComponent implements OnInit {
  @Input() size: string = ''; // Can be xs/sm/md/lg/xl/xxl
  @Input() image: string = ''; // Can be xs/sm/md/lg/xl/xxl
  @Input() customSize: number = null;

  images: any[] = [
    'assets/img/avatars/hieu.png',
    'assets/img/avatars/captain.png',
    'assets/img/avatars/blackpanther.png',
    'assets/img/avatars/blackwidow.png',
    'assets/img/avatars/ironman.png',
    'assets/img/avatars/spiderman.png',
    'assets/img/avatars/hulk.png',
    'assets/img/avatars/scarwitch.png',
    'assets/img/avatars/thor.png',
    'assets/img/avatars/thanos.png'
  ];
  imageUrl: string;

  constructor() {
    console.log(this.image);
    console.log(this.customSize);
    
    if(this.image != '')
    {
      this.imageUrl = this.image
    }else{
      let randomIndex = Math.floor(Math.random() * (this.images.length - 1));
      this.imageUrl = `${this.images[randomIndex]}`;
    }
  }

  ngOnInit() {}

}
