import { Component, OnInit, Input } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'connect-item',
  templateUrl: './connect-item.component.html',
  styleUrls: ['./connect-item.component.scss'],
})
export class ConnectItemComponent implements OnInit {
  @Input() jobCard  
  constructor(public nav: NavController) { }

  ngOnInit() {}

  goToJobDetail(){
    this.nav.navigateForward("tabs/home/jd/"+this.jobCard.id);
  }
}
