import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'post-item',
  templateUrl: './post-item.component.html',
  styleUrls: ['./post-item.component.scss'],
})
export class PostItemComponent implements OnInit {
  @Input() postData: any;
  @Input() showOptions: boolean = true;
  @Output() onClickDetail = new EventEmitter();
  @Output() onClickProfile = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  handleClick() {
    this.onClickDetail.emit();
  }

  handleGoToProfile() {
    this.onClickProfile.emit();
  }
}
